<template>
  <div class="registration-form">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2>{{ 'registration.form.title' | text }}</h2>
          <div class="mt-4">
            <v-row 
              no-gutters
              v-for="item in getStatus"
              :key="item.title"
            >
              <v-col
                cols="3"
                sm="3"
                md="3"
                lg="2"
              >
                <p>{{ item.title | text }}</p>
              </v-col>
              <v-col
                cols="9"
                sm="9"
                md="9"
                lg="10"
              >
                <p>{{ `: ${item.value}` }}</p>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <form-register-sth v-if="isSth" :formId="formId" @handleBack="handleBack"/>
          <form-register-mth v-if="isMth" :formId="formId" @handleBack="handleBack"/>
          <form-register-mthu v-if="isMthu" :formId="formId" @handleBack="handleBack"/>
          <form-register-mmin v-if="isMmin" :formId="formId" @handleBack="handleBack"/>
          <form-register-mdiv v-if="isMdiv" :formId="formId" @handleBack="handleBack"/>
          <form-register-sert v-if="isSert" :formId="formId" @handleBack="handleBack"/>
        </v-card>
        <dialog-confirm ref="dialogConfirmForm" :info="info" :action="handleMove"/>
      </div>
    </v-container>
  </div>
</template>

<script>
import { get } from 'dot-prop'
import FormRegisterSth from '@/components/form/formRegisterSTH'
import FormRegisterMth from '@/components/form/formRegisterMTH'
import FormRegisterMthu from '@/components/form/formRegisterMTHU'
import FormRegisterMmin from '@/components/form/formRegisterMMIN'
import FormRegisterMdiv from '@/components/form/formRegisterMDIV'
import FormRegisterSert from '@/components/form/formRegisterSERT'
import DialogConfirm from '@/components/common/DialogConfirm'
// Constants
import { text } from '@/apps/web/constants/text'
import {REGISTRATION_DETAIL} from '@/constants/pages'

import { getConfig } from '@/utils/request'
  export default {
    name: 'RegistrationForm',
    components: {
      FormRegisterSth,
      FormRegisterMth,
      FormRegisterMthu,
      FormRegisterMmin,
      FormRegisterMdiv,
      FormRegisterSert,
      DialogConfirm
    },
    data () {
      return {
        isDone: false,
        info: {
          desc: 'Perubahan yang anda lakukan tidak akan tersimpan, Anda yakin untuk melanjutkan?'
        },
        path: ''
      }
    },
    beforeRouteEnter (to, from, next) {
      if (from.name === null) {
        next(vm => {
          vm.isDone = true
          const name = REGISTRATION_DETAIL
          vm.$router.replace({
            name,
            query: {
              prodi: vm.$route.query.prodi,
              formId: vm.formId,
              periode: vm.$route.query.periode || new Date().getFullYear()
            }
          })
        })
      } else {
        next()
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.isDone) {
        next()
      } else {
        this.path = to.name
        this.$refs.dialogConfirmForm && this.$refs.dialogConfirmForm.show()
      }
    },
    created () {
      window.addEventListener('beforeunload', this.handleBeforeUnload)
    },
    beforeDestroy () {
      window.removeEventListener('beforeunload', this.handleBeforeUnload)
    },
    computed: {
      isDashboard () {
        const config = getConfig()
        const path = this.$route.path
        return config.role === 'admin' && path.indexOf('dashboard') !== -1
      },
      getStatus() {
        const programStudy = this.$route.query.prodi
        const period = this.$route.query.periode || new Date().getFullYear()
        const prodiTitle = text[`program.studi.${programStudy}.title`]
        return [{
          title: 'registration.detail.status.program',
          value: prodiTitle
        }, {
          title: 'registration.detail.status.periode',
          value: period
        }]
      },
      formId() {
        return this.$route.query.formId
      },
      isSth() {
        return get(this.$route.query, 'prodi', 'sth') === 'sth'
      },
      isMth() {
        return get(this.$route.query, 'prodi', 'sth') === 'mth'
      },
      isMthu() {
        return get(this.$route.query, 'prodi', 'sth') === 'mthu'
      },
      isMmin() {
        return get(this.$route.query, 'prodi', 'sth') === 'mmin'
      },
      isMdiv() {
        return get(this.$route.query, 'prodi', 'sth') === 'mdiv'
      },
      isSert() {
        return get(this.$route.query, 'prodi', 'sth') === 'sert'
      }
    },
    methods: {
      handleBeforeUnload (event) {
        event.preventDefault()
        event.returnValue = ''
      },
      handleBack (val) {
        this.isDone = val
        if (this.isDone) {
          const name = REGISTRATION_DETAIL
          this.$router.replace({
            name,
            query: {
              prodi: this.$route.query.prodi,
              formId: this.formId,
              periode: this.$route.query.periode || new Date().getFullYear()
            }
          }).catch(() => {})
        }
      },
      handleMove () {
        this.isDone = true
        const query = {
          prodi: this.$route.query.prodi,
          formId: this.formId,
          periode: this.$route.query.periode || new Date().getFullYear()
        }
        const name = REGISTRATION_DETAIL
        this.path = this.path ? this.path : name
        this.$router.replace({
          name: this.path,
          query: this.path === name ? query : {}
        }).catch(() => {})
      }
    },
  }
</script>

<style lang="scss" scoped>
.registration-form {
  font-size: 14px;
  .title-14 {
    font-weight: bold;
    font-size: 14px;
  }
}
</style>